<template>
  <div class="login-wrapper">

    <div class="login-content">
      <div class="login-logo">
        <logo/>
      </div>

      <form action="" @submit.prevent="submit">
        <div class="auth_block" v-if="!isCodePart">
          <div class="login-form">
            <form-group label="Email">
              <input type="text" autocomplete="email" v-model="form.email" class="form-control" :class="{valid:form.email}">
            </form-group>

            <form-group label="Password">
              <input type="password" autocomplete="current-password" v-model="form.password" class="form-control" :class="{valid:form.password}">
            </form-group>
          </div>
          <div class="login-bottom-panel">
            <router-link to="/reset-password" content="open-modal-button">
              Forgot password?
            </router-link>
          </div>
        </div>

        <div class="auth_block" v-if="isCodePart">
          <div class="login-form" style="margin-bottom: 20px">
            <form-group label="Please enter your 2FA code">
              <code-input v-model="form.code" @fulfilled="submit" :autofocus="true"/>
            </form-group>

            <span class="login-form-description">
              If you don't have access to your key and backup code is also lost, please contact us via <a :href="'mailto:' + settings.email_support">{{ settings.email_support }}</a> to verify your identity and regain access to your account
            </span>
          </div>
        </div>

        <button type="submit" class="default-button login-button" :disabled="!loginButtonEnabled">
          Login
        </button>
      </form>

      <div v-show="errors" class="login-button default-button error error-summary" style="margin-top: 20px; display: block;">
        {{ getFirstError() }}
      </div>
    </div>
  </div>
</template>
<script>
import FormGroup from '../libs/forms/FormGroup'
import CodeInput from "@/libs/CodeInput";
import Logo from "@/libs/Logo";
import {mapState} from "vuex";
import hasProperty from 'lodash/has'

export default {
  name: 'Login',
  components: {
    Logo,
    CodeInput,
    FormGroup
  },
  data () {
    return {
      isCodePart: false,
      isWaitingSubmit: false,

      form: {
        email: '',
        password: '',
        code: ''
      },

      errors: null
    }
  },
  created() {
    // Fix when logout is completed. Close all modals
    this.$modal.hideAll()
  },
  watch: {
    'errors': {
      handler (newVal) {
        if (newVal?.code && this.form.code.length === 6) {
          this.form.code = ''
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapState({
      settings: state => state.site.settings
    }),
    loginButtonEnabled: function () {
      return !this.isWaitingSubmit
    },
    formData () {
      return {
        username: this.form.email,
        password: this.form.password,
        code: this.form.code
      }
    }
  },
  methods: {
    submit () {
      if (!this.loginButtonEnabled) {
        return
      }

      this.errors = null
      this.isWaitingSubmit = true

      this.$store.dispatch('auth.login', this.formData).then(async ({ data }) => {
        const errorsLength = Object.keys(data.errors).length
        if (errorsLength > 0) {
          this.errors = data.errors

          if (hasProperty(data.errors, 'code') && !this.isCodePart && errorsLength === 1) {
            this.isCodePart = true
            this.errors = null
          }
        } else {
          await this.$store.dispatch('getUser')
          await this.$router.push({name: 'main'})
        }
      }).finally(() => {
        this.isWaitingSubmit = false
      })
    },

    /**
     * Display first message in to summary block
     */
    getFirstError () {
      for (const key in this.errors) {
        const value = this.errors[key][0]
        if (value.length > 0) {
          return value
        }
      }
    }
  }
}
</script>

<style lang="less">
  .login-form-description {
    margin-top: 15px;
    display: block;
    line-height: 1.45em;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
  }
</style>
